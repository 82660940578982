import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt'

// imports dynamiques
const VueFeather = defineAsyncComponent(() => import(/* webpackChunkName: "vue-feather" */ 'vue-feather'))
const Particles = defineAsyncComponent(() => import(/* webpackChunkName: "particlesjs" */ 'vue3-particles').then((particles) => particles.ParticlesComponent))
const emitter = mitt()

const app = createApp(App)
  .use(store)
  .use(router)
  .component('Particles', Particles)
  .component('vue-feather', VueFeather)

app.config.globalProperties.emitter = emitter
app.mount('#app')
