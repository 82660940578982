<template>
  <div class="signup hors-dashboard">
    <!-- logo -->
    <div class="img-large">
      <picture>
        <source srcset="/assets/img/logo-monogramme.webp" type="image/webp" />
        <img class="logo-icon" src="/assets/img/logo-monogramme.png" alt />
      </picture>
      <picture>
        <source srcset="/assets/img/logo-texte.webp" type="image/webp" />
        <img class="logo-text" src="/assets/img/logo-texte.png" alt />
      </picture>
    </div>
    <!-- formulaire d'inscription -->
    <form @submit.prevent="formHandler">
      <h1>CRÉEZ VOTRE PROFIL</h1>
      <div class="text">
        <p>Il n'est pas obligatoire de reprendre l'adresse mail utilisée pour la commande</p>
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="user" />
        </div>
        <label for="name">Nom</label>
        <input type="text" name="name" v-model="name" placeholder="Nom" />
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="mail" />
        </div>
        <label for="mail">Adresse e-mail</label>
        <input type="email" name="mail" v-model="mail" placeholder="Adresse e-mail" />
      </div>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="lock" />
        </div>
        <label for="password">Mot de passe</label>
        <input type="password" name="password" v-model="password" placeholder="Mot de passe" />
      </div>
      <input class="button" type="submit" value="VALIDER" />
    </form>
    <router-link class="button button-secondary button-link" :to="{ name: 'Login' }">
      <span>J'AI DÉJÀ UN COMPTE</span>
    </router-link>
    <div class="overlay"></div>
    <LegalLinks />
  </div>
</template>

<script>
import LegalLinks from "@/components/LegalLinks.vue"
import { mapActions } from "vuex"

export default {
  components: {
    LegalLinks,
  },
  data () {
    return {
      name: "",
      mail: "",
      password: "",
    }
  },
  methods: {
    // récupère les actions login et fetchDetective du store
    ...mapActions(["signup", "fetchDetective", "setNotif"]),
    // fonction qui est éxécuté en cas d'envoi du formulaire
    formHandler () {
      // créé l'objet détective à partir des champs de formulaires
      const detective = {
        name: this.name.trim(),
        mail: this.mail.trim(),
      }
      if (detective.name && detective.mail && this.password) {
        // éxécute la fonction signup du store pour inscrire l'utilisateur
        this.signup({ password: this.password, detective: detective }).then(
          () => this.$router.push({ name: "Login" }) // redirige vers la page de connexion
        )
      } else {
        this.setNotif("Veuillez remplir tout les champs")
      }
    },
  },
}
</script>