<template>
  <div class="legal-links">
    <a
      href="/conditions-generales-d-utilisation.html"
      target="_blank"
      class="link"
    >
      Conditions générales d'utilisation
    </a>
    <a href="/politique-de-confidentialite.html" target="_blank" class="link">
      Politique de confidentialite
    </a>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/_mixins.scss";

.legal-links {
  a {
    text-decoration: underline;
  }
}

.hors-dashboard {
  .legal-links {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1.5rem;
    font-size: 0.9rem;

    a:first-of-type {
      margin-right: 1rem;
    }

    @include mobile {
      position: initial;
      font-size: 2rem;
      margin-bottom: 56px;
    }
  }
}

.help {
  .legal-links {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @include mobile {
      flex-direction: column;
      font-size: 2rem;
    }
  }
}
</style>