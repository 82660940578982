
export default {
  state: {
    notification: null,
    buttonType: '',
    data: {}
  },
  mutations: {
    SET (state, { notif, buttonType, data }) {
      state.notification = notif
      state.buttonType = buttonType
      state.data = data
    },
    REMOVE (state) {
      state.notification = null
      state.buttonType = ''
      state.data = {}
    }
  },
  actions: {
    setNotif ({ commit }, notif) {
      return commit('SET', { notif: notif, buttonType: 'close', data: {} })
    },
    setDocumentNotif ({ commit }, { notif, document, boxContent, documentName }) {
      return commit('SET', {
        notif: notif,
        buttonType: 'document',
        data: {
          type: document.type,
          documentId: document.documentId,
          box: boxContent,
          name: documentName
        }
      })
    },
    setReinforcmentNotif ({ commit }, { notif, index, texts, image, images }) {
      return commit('SET', {
        notif: notif,
        buttonType: 'reinforcment',
        data: {
          index: index,
          texts: texts,
          image: image,
          images: images
        }
      })
    },
    setDeleteNotif ({ commit }) {
      return commit('SET', {
        notif: 'La suppression de votre compte entraînera la perte de toutes vos données. Êtes-vous sûr de vouloir supprimer votre compte ?',
        buttonType: 'delete',
        data: {}
      })
    },
    removeNotif ({ commit }) {
      return commit('REMOVE')
    }
  }
}
