<template>
  <div>
    <!-- main content -->
    <router-view />
    <!-- notification modal -->
    <NotificationContainer />
    <!-- background animation -->
    <Particles
      id="background"
      alt="animation de fond"
      :particlesInit="particlesInit"
      :options="{
        background: {
          color: {
            value: '#222',
          },
        },
        fpsLimit: 30,
        interactivity: {
          detectsOn: 'window',
          events: {
            onHover: {
              enable: true,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            repulse: {
              distance: 150,
              duration: 1,
            },
          },
        },
        particles: {
          color: {
            value: '#bd2124',
          },
          links: {
            color: '#bd2124',
            distance: 200,
            enable: true,
            opacity: 1,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 2000,
            },
            value: 100,
          },
          opacity: {
            value: 1,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 4,
          },
        },
        detectRetina: true,
      }"
    />
  </div>
</template>

<script>
import NotificationContainer from "@/components/NotificationContainer.vue";
import { mapActions } from "vuex";
import { loadFull } from "tsparticles";

export default {
  components: {
    NotificationContainer,
  },
  methods: {
    ...mapActions(["setIsMobile"]),
    // Notre média query étant complexe, on la gère via cette fonction js plutot que via le css.
    // Cette fonction vérifie si il y à assez de place pour afficher les trois blocks centraux de la page Home.
    // S'il n'y a pas la place, il ajoute la class .mobile au tag html de la page et passe la valeur isMobile du store à true.
    // Sinon il retire cette class et passe isMobile à false.
    mq() {
      const html = document.getElementsByTagName("html")[0];
      // maxHomeWidth = 109.252vh + 27.5vw + 76.8px = la largeur nécéssaire à afficher les trois blocks centreaux de la page home
      const maxHomeWidth =
        (109.252 / 100) * window.innerHeight + (27.5 / 100) * window.innerWidth + 76.8;
      html.classList.toggle("mobile", window.innerWidth < maxHomeWidth);
      this.setIsMobile(window.innerWidth < maxHomeWidth);
    },
    particlesInit: async (engine) => {
      await loadFull(engine);
    },
    particlesLoaded: async (container) => {
      console.log("Particles container loaded", container);
    },
  },
  created() {
    // à la création de l'app on éxécute mq une première fois puis on l'éxécute à chaque changement de taille de la fenetre
    this.mq();
    window.onresize = this.mq;
  },
};
</script>

<style lang="scss">
// import d'un reset des styles par default
@import "@/assets/scss/_normalize.scss";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";
@import "@/assets/scss/_font.scss";

html {
  font-size: calc(1px + 1.39vh);
  &.mobile {
    font-size: 2vw;
  }
}
html,
body {
  margin: 0;
  padding: 0;
  position: relative;
}
// default style
#app {
  font-family: Oswald, Ubuntu, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-align: center;
  color: $white;
  font-size: 1.25rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @include mobile {
    height: initial;
  }

  h1 {
    font-size: 1.5rem;
    @include mobile {
      font-size: 3.5rem;
      font-weight: 400;
    }
  }

  source {
    display: none !important;
  }

  button,
  a {
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }

  * {
    box-sizing: border-box;

    // paramétrage de la scrollbar lors d'overflow
    scrollbar-color: $red lighten($grey, 10%) !important;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.4rem;
      z-index: 1;
    }
    &::-webkit-scrollbar-track {
      background-color: lighten($grey, 10%);
      z-index: 2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $red;
      z-index: 3;
    }

    // focus par défault
    &:focus {
      outline: 1px dotted $light-grey !important;
    }
  }
}

// styles pour ParticlesJS
#background {
  position: fixed;
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -99;
}

// style de la barre de chargement
#nprogress {
  z-index: 999;
  position: fixed;
  top: 0;
  height: 0.25rem;
  width: 100vw;
  .bar {
    height: 0.25rem;
    background-color: $red;
  }
  .spinner {
    position: fixed;
    top: 0.5rem;
    right: 0.25rem;
    .spinner-icon {
      width: 0.75rem;
      height: 0.75rem;
      border: 2px solid transparent;
      border-top-color: $red;
      border-left-color: rgba($red, 50%);
      border-radius: 100%;
      animation: nprogress-spinner 400ms linear infinite;
      @keyframes nprogress-spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

// overlay sombre sur les pages de connexion / inscription / choix de box / introduction
.overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-color: rgba($black, 0.4);
}

// style des bouttons
.button {
  display: flex;
  align-items: center;
  background-color: $red;
  color: $white;
  border: none;
  font-weight: 600;
  height: 3.25rem;
  padding: 0.5rem 3.2rem;
  margin: 0.625rem auto;
  @include mobile {
    height: 7rem;
    padding: 1rem 3.5rem;
    font-size: 2.75rem;
    margin: 2rem auto;
  }
  //.button-secondary pour les bouttons gris
  &-secondary,
  &:not(.done):disabled {
    background-color: $grey;
    color: darken($white, 25%);
  }
  //.button-outline pour les boutton transparent avec une bordure
  &-outline {
    background-color: transparent;
    border: solid 1px $white;
    margin: -1px;
    height: 4vh;
    font-size: 1.25rem;
    padding: 0 0.6rem;
    .icon > * {
      display: inline-block;
      height: 2.5em;
      width: 2.5em;
      padding: 0.6em;
      @include mobile {
        height: 3rem;
        width: 3rem;
        padding: 0;
      }
    }
    .text {
      display: inline-block;
      height: 2.5em;
      padding: 0.6em;
      @include mobile {
        height: fit-content;
        font-size: 2rem;
        font-weight: 300;
      }
    }
  }
  // hovers pour les bouttons standard, secondary et outline
  &:not(.button-large):not(.button-like) {
    &:hover {
      background-color: lighten($red, 5%);
    }
    &.button-secondary:hover {
      background-color: lighten($grey, 5%);
    }
    &.button-outline:hover {
      background-color: $white;
      color: $black;
    }
  }
  //.button-link pour les corections pour les liens qui ressemblent à des buttons
  &-link {
    text-decoration: none;
  }
  // bouttons de grande taille composé d'une icone sur la gauche, d'un text et pour les .button-large d'une flèche sur la droite
  // ils servent principalement pour les titres de pages, les objectifs et les bouttons de box de la boite de réception
  &-large,
  &.button-like {
    width: 30rem;
    height: 8rem;
    margin-bottom: 0.7rem;
    padding: 0 !important;
    @include mobile {
      width: 40rem;
      height: 12rem;
      margin-bottom: 0.5em;
    }
    .icon {
      flex-shrink: 0;
      height: 5rem;
      width: 5rem;
      margin: 1.5rem;
      background-color: $black;
      border-radius: 100%;
      @include mobile {
        position: relative;
      }
      & > * {
        position: relative;
        top: calc(50% - 1rem);
        width: 2rem;
        height: 2rem;
        @include mobile {
          position: absolute;
          top: calc(50% - 1rem);
          left: calc(50% - 1rem);
        }
      }
    }
    .text {
      margin-left: 0.45rem;
      margin-right: auto;
      height: max-content;
      text-align: left;
      max-width: 45%;
      font-size: 2.5rem;
      font-weight: 600;
      @include mobile {
        margin-left: 0.45rem;
      }
    }
    .arrow {
      position: relative;
      flex-shrink: 0;
      height: inherit;
      width: 12%;
      background-color: rgba($color: $black, $alpha: 0.3);
      transition: width cubic-bezier(0.175, 0.885, 0.32, 1.1) 0.25s;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-height: 35%;
      }
    }
    &.button-secondary .arrow {
      background-color: $black;
    }
    &.done {
      .arrow {
        color: rgba($color: $black, $alpha: 0.3);
      }
    }
    &:not(.button-disabled):not(.done) {
      &:hover {
        .arrow {
          width: 22%;
        }
      }
      &:active {
        .arrow {
          background-color: rgba($color: $black, $alpha: 0.5);
        }
      }
    }
  }
  &.button-like {
    .text {
      height: initial;
      max-width: initial;
    }

    &-title {
      position: relative;
      width: fit-content;
      height: fit-content;
      background-color: $black;
      margin-bottom: 4vh;
      box-shadow: 1px 0 2px $black;
      @include mobile {
        flex-direction: column;
        height: fit-content;
        width: 100%;
        margin-top: 3rem;
      }
      &:hover {
        background-color: $black;
      }

      .text {
        max-width: initial;
        margin: 3vh 4vh 3vh 2vh;
        @include mobile {
          text-align: center;
          font-size: 3rem;
          line-height: 4.5rem;
          margin: 3rem;
          margin-top: 1rem;
        }
      }
      .icon {
        display: grid;
        place-items: center;
        @include mobile {
          margin-bottom: 0;
          img {
            margin: 0;
          }
        }
      }
      .recolored {
        position: initial;
        margin-left: 4vh;
        filter: invert(100%) sepia(0%) saturate(7441%) hue-rotate(176deg) brightness(101%)
          contrast(86%);
        transform: scale(1.75);
      }
      .vue-feather {
        position: initial;
        margin-left: 4vh;
        transform: scale(1.25);
        @include mobile {
          margin: 0;
          margin-top: 3rem;
        }
      }
    }
  }
  // les bouttons de retours, souvent outline
  &.return {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    @include mobile {
      position: initial;
      transform: none;
      padding: 0 2rem;
      height: fit-content;
      .text {
        padding: 0;
        margin-left: 1rem;
      }
    }
  }
}

// style pour les pages de connection / insription / etc
.hors-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  @include mobile {
    height: initial;
    font-size: 2.5rem;
    font-weight: 200;
  }
  .img {
    // l'orsque la page n'utilise que l'icone de détectivebox
    &-small {
      width: 4.1rem;
      margin: 5rem 0 3rem 0;
      @include mobile {
        width: 10vw;
      }
    }
    // l'orsque la page utilise le logo complet
    &-large {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-icon {
        width: 4.1rem;
        margin: 2rem 0;
        @include mobile {
          width: 10vw;
        }
      }
      .logo-text {
        width: 26.1rem;
        max-width: 550px;
        @include mobile {
          width: 45%;
        }
      }
    }
  }
  .choice {
    margin-bottom: auto;
  }
  & > .text {
    margin: 5vh 0;
    h1 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}

// style pour les formulaires
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    display: none;
  }
  // chaque input container est composé d'une icone, d'un label non visible et d'un input
  .input-container {
    display: flex;
    align-items: center;
    height: 3.2rem;
    margin-bottom: 1.25rem;
    border: solid 1px $red;
    background-color: $red;
    z-index: 0;
    @include mobile {
      height: 7rem;
      margin-bottom: 2rem;
    }
    .fa {
      display: grid;
      place-items: center;
      height: 2.5em;
      width: 2.5em;
      z-index: 1;
      svg {
        height: 1.25em;
      }
    }

    input {
      display: inline-block;
      height: calc(2.5em - 2px);
      border: solid 1px $red;
      background-color: $black;
      padding: 0 1em;
      color: $white;
      z-index: 1;
      @include mobile {
        height: calc(7rem - 2px);
      }
      &::placeholder {
        color: $light-grey;
      }
    }
  }
}
</style>
