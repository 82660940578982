
export default {
  state: {
    type: '',
    documentId: '',
    box: {},
    name: '',
    new: '',
  },
  mutations: {
    SETDOC (state, { type, documentId, box, name, new_ }) {
      state.type = type
      state.documentId = documentId
      state.box = box
      state.name = name
      state.new = new_
    },
    REMOVEDOC (state) {
      state.type = ''
      state.documentId = ''
      state.box = {}
      state.name = ''
      state.new = ''
    }
  },
  actions: {
    setDocument ({ commit }, { type, documentId, box, name, new_ }) {
      return commit('SETDOC', { type: type, documentId: documentId, box: box, name: name, new_: new_ })
    },
    resetDocument ({ commit }) {
      return commit('REMOVEDOC')
    }
  }
}
