<template>
  <!-- logo -->
  <div class="login hors-dashboard">
    <div class="img-large">
      <picture>
        <source srcset="/assets/img/logo-monogramme.webp" type="image/webp" />
        <img class="logo-icon" src="/assets/img/logo-monogramme.png" alt />
      </picture>
      <picture>
        <source srcset="/assets/img/logo-texte.webp" type="image/webp" />
        <img class="logo-text" src="/assets/img/logo-texte.png" alt />
      </picture>
    </div>
    <!-- formulaire de demande de réinitialisation de mot de passe -->
    <form @submit.prevent="formHandler">
      <h1>Mot de passe perdu</h1>
      <div class="input-container">
        <div class="fa">
          <vue-feather tag="div" type="mail" />
        </div>
        <label for="mail">Adresse e-mail</label>
        <input type="email" name="mail" v-model="mail" placeholder="Adresse e-mail" />
      </div>
      <input class="button" type="submit" value="ENVOYER" />
    </form>
    <router-link class="button button-secondary button-link" :to="{ name: 'Login' }">
      <span>RETOUR</span>
    </router-link>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  data () {
    return {
      mail: "",
    }
  },
  methods: {
    // récupère l'action sendResetMail du store
    ...mapActions(["sendResetMail", "setNotif"]),
    //fonction qui est éxécuté en cas d'envoi du formulaire
    async formHandler () {
      if (this.mail) {
        // éxécute sendResetMail avec la valeur du formulaire puis renvoi vers la page de connection
        await this.sendResetMail(this.mail)
        this.$router.push({ name: "Login" })
      } else {
        this.setNotif("Veuillez remplir le champ.")
      }

    },
  },
}
</script>
