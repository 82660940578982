import ApiServices from '@/services/ApiServices'

const errorNotif = (dispatch, message) => {
  return dispatch('setNotif', message, { root: true })
}

export default {
  state: {
    boxList: [],
    currentBox: {},
    loaded: false
  },
  mutations: {
    SET_BOX (state, boxContent) {
      state.currentBox = boxContent
      state.loaded = true
    },
    SET_BOX_LIST (state, boxList) {
      state.boxList = boxList
    },
    UNLOAD (state) {
      state.loaded = false
      state.currentBox = {}
    }
  },
  actions: {
    fetchBoxContent ({ commit, dispatch }, box) {
      return ApiServices.getBoxContent(box)
        .then(res => {
          if (res.status === 200) {
            commit('SET_BOX', res.data.boxContent)
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion avec le serveur: " + "\n" + err.message))
    },
    fetchBoxList ({ commit, dispatch }) {
      return ApiServices.getBoxList()
        .then(res => {
          if (res.status === 200) {
            commit('SET_BOX_LIST', res.data.boxList)
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion avec le serveur: " + "\n" + err.message))
    },
    async getGoalAnswers ({ dispatch }, { detectiveId, box, objective }) {
      try {
        const res = await ApiServices.getGoalAnswers(detectiveId, box, objective)
        if (res.status === 200) {
          return res.data.answers
        } else {
          errorNotif(dispatch, res.data.message)
        }
      } catch (err) {
        errorNotif(dispatch, "Erreur de connexion avec le serveur: " + "\n" + err.message)
      }
    },
    // getGoalAnswers ({ dispatch }, { detectiveId, box, objective }) {
    //   return ApiServices.getGoalAnswers(detectiveId, box, objective)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         return res.data.answers
    //       } else {
    //         errorNotif(dispatch, res.data.message)
    //       }
    //     })
    //     .catch((err) => errorNotif(dispatch, "Erreur de connexion avec le serveur: " + "\n" + err.message))
    // },
    unloadBox ({ commit }) {
      return commit('UNLOAD')
    }
  },
  getters: {

  }
}
