import { createStore } from 'vuex'
import detective from '@/store/modules/detective.js'
import box from '@/store/modules/box.js'
import notif from '@/store/modules/notif.js'
import document from '@/store/modules/document'

const store = createStore({
  state: {
    isMobileMenuOpen: false,
    isMobile: false
  },
  mutations: {
    OPEN_MOBILE_MENU (state) {
      state.isMobileMenuOpen = true
    },
    CLOSE_MOBILE_MENU (state) {
      state.isMobileMenuOpen = false
    },
    SET_IS_MOBILE (state, value) {
      state.isMobile = value
    }
  },
  actions: {
    openMobileMenu ({ commit }) {
      return commit('OPEN_MOBILE_MENU')
    },
    closeMobileMenu ({ commit }) {
      return commit('CLOSE_MOBILE_MENU')
    },
    setIsMobile ({ commit }, value) {
      return commit('SET_IS_MOBILE', value)
    }
  },
  modules: {
    detective,
    box,
    notif,
    document
  }
})

export default store
