import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 100000,
})

export default {
  signup (password, detective) {
    return apiClient.post('/detective/signup', {
      password: password,
      detective: detective
    })
  },
  login (password, mail) {
    return apiClient.post('detective/login', {
      password: password,
      mail: mail
    })
  },
  getDetective (detectiveId) {
    return apiClient.post('detective/', { detectiveId: detectiveId })
  },
  editDetecive (detectiveId, form) {
    return apiClient.post('detective/edit/user', {
      detectiveId: detectiveId,
      form: form
    })
  },
  editAuth (detectiveId, form) {
    return apiClient.post('detective/edit/auth', {
      detectiveId: detectiveId,
      form: form
    })
  },
  deleteDetective (detectiveId) {
    return apiClient.post('detective/delete', { detectiveId: detectiveId })
  },
  resendMail (detectiveId) {
    return apiClient.post('detective/mail/resend', { detectiveId: detectiveId })
  },
  validateMail (token) {
    return apiClient.get('detective/mail/validate/' + token)
  },
  sendResetMail (mail) {
    console.log('test')
    console.log(mail)
    return apiClient.post('detective/reset/send', { mail: mail })
  },
  reset (token, authId, password) {
    return apiClient.post('detective/reset', { token: token, authId: authId, password: password })
  },
  inboxAddDocument (detectiveId, box, document) {
    return apiClient.post('detective/inbox/add', {
      detectiveId: detectiveId,
      box: box,
      document: document
    })
  },
  inboxDocumentSeen (detectiveId, box, documentId) {
    return apiClient.post('detective/inbox/seen', {
      detectiveId: detectiveId,
      box: box,
      documentId: documentId
    })
  },
  viewIntro (detectiveId, box) {
    return apiClient.post('detective/gamesave/intro', {
      detectiveId: detectiveId,
      box: box
    })
  },
  updateSuspects (detectiveId, box, suspects) {
    return apiClient.post('detective/gamesave/suspects/update', {
      detectiveId: detectiveId,
      box: box,
      suspects: suspects
    })
  },
  validateObjective (detectiveId, box, objective, answers) {
    return apiClient.post('detective/gamesave/objective/validate', {
      detectiveId: detectiveId,
      box: box,
      objective: objective,
      answers: answers
    })
  },
  validateBox (detectiveId, box, suspects) {
    return apiClient.post('detective/gamesave/box/validate', {
      detectiveId: detectiveId,
      box: box,
      suspects: suspects
    })
  },
  getScore (detectiveId) {
    return apiClient.post('detective/score', { detectiveId: detectiveId })
  },
  checkLogin (detectiveId) {
    return apiClient.post('detective/checklogin', { detectiveId: detectiveId })
  },
  getBoxContent (box) {
    return apiClient.post('content/', { box: box })
  },
  getBoxList () {
    return apiClient.get('content/list')
  },
  getGoalAnswers (detectiveId, box, objective) {
    return apiClient.post('content/goal/answers', {
      detectiveId: detectiveId,
      box: box,
      objective: objective
    })
  },
  setAuthorization (jwt) {
    apiClient.defaults.headers.common.Authorization = jwt
  }
}
