<template>
  <button :class="this.class" >
    <div class="icon"><vue-feather tag="div" :type="this.icon" /></div>
    <span class="text">BOX {{ this.box }}</span>
    <vue-feather tag="div" type="chevron-right" class="arrow" />
  </button>
</template>

<script>
export default {
  props: {
    box: Number,
    isDisabled: Boolean
  },
  computed: {
    class() {
      return this.isDisabled ? 'button button-large button-secondary button-disabled' : 'button button-large'
    },
    icon() {
      return this.isDisabled ? 'lock' : 'unlock'
    }
  }
}
</script>