<template>
  <div class="hors-dashboard">
    <picture>
      <source srcset="/assets/img/logo-monogramme.webp" type="image/webp" />
      <img class="img-small" src="/assets/img/logo-monogramme.png" alt />
    </picture>
    <div class="text">
      <h1>Bienvenue, Détective {{ this.name }} !</h1>
      <p>Veuillez sélectionner votre niveau d'avancement.</p>
    </div>
    <div class="choice">
      <BoxButton
        v-for="box in boxList"
        :key="box"
        :box="box"
        :isDisabled="this.boxIsLocked(box)"
        @click="this.clickHandler(box)"
      />
    </div>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BoxButton from "@/components/BoxButton.vue"
export default {
  components: {
    BoxButton,
  },
  computed: {
    ...mapState({
      loaded: (state) => state.box.loaded,
      boxList: (state) => state.box.boxList,
      gameSave: (state) => state.detective.gameSave,
      name: (state) => state.detective.name,
    }),
  },
  methods: {
    ...mapActions(["setNotif", "fetchBoxContent"]),
    boxIsLocked (box) {
      if (box === 1) {
        return false
      }
      const precedentBox = this.gameSave.filter(
        (gamestate) => gamestate[0] === String(Number(box) - 1)
      )
      if (precedentBox.length > 0 && precedentBox[0][1].done) {
        return false
      }
      return true
    },
    clickHandler (box) {
      if (this.boxIsLocked(box)) {
        this.setNotif(
          "Afin de débloquer cette box, vous devez terminer la box précédente."
        )
      } else {
        this.fetchBoxContent(box).then(() => {
          if (this.gameSave[box - 1] && this.gameSave[box - 1][1].seenIntro) {
            return this.$router.push({ name: "Home" })
          }
          return this.$router.push({ name: "Intro" })
        })
      }
    },
  },
}
</script>

