<template>
  <div class="hors-dashboard">
    <div class="img-large">
      <picture>
        <source srcset="/assets/img/logo-monogramme.webp" type="image/webp" />
        <img class="logo-icon" src="/assets/img/logo-monogramme.png" alt="" />
      </picture>
      <picture>
        <source srcset="/assets/img/logo-texte.webp" type="image/webp" />
        <img class="logo-text" src="/assets/img/logo-texte.png" alt="" />
      </picture>
    </div>
    <p>Merci d'avoir validé votre adresse mail.</p>
    <a class="button button-link" href="/"><span>Valider</span></a>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    token: String,
  },
  methods: {
    ...mapActions(["validateMail"]),
  },
  created() {
    this.validateMail(this.token);
  },
};
</script>

<style lang="scss">
.mobile {
  p {
    margin: 5vh 4rem;
    font-size: 3rem;
    font-weight: 400;
    line-height: 4.5rem;
  }
}
</style>